@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  @apply h-screen;
}

.site-main {
  @apply flex-col min-h-screen bg-main flex items-center justify-center;
}

.site-dashboard {
  @apply flex-col min-h-screen bg-main flex p-12;
}

.dashboard-title {
  @apply flex-row pt-16 mb-4;
}

.dashboard-title-text {
  @apply font-bold text-7xl flex justify-center mb-8;
}

.dashboard-title-description {
  @apply text-3xl bg-white rounded-md p-4 mx-4 shadow-lg;
}

.dashboard-entry {
  @apply px-20 pt-10 w-full justify-center border-none;
}

.dashboard-page {
  @apply px-4 pt-10 w-full justify-center;
}
.dashboard-block {
  @apply bg-secondary rounded-md p-4 mb-4 shadow-lg;
}
.dashboard-block-1 {
  @apply bg-primary rounded-md p-4 mb-4 shadow-lg;
}
.dashboard-block-title {
  @apply text-3xl font-bold;
}
.dashboard-block-description {
  @apply text-xl font-bold text-coolGray-600 mt-4 mb-2;
}
.dashboard-block-text-input {
  @apply w-full rounded-md border-coolGray-300;
}


.user-entry {
  @apply px-20 pt-10 w-full justify-center border-none;
}
.dashboard-drag {
  @apply mx-20 my-5 px-5 pt-5 w-11/12 justify-center rounded shadow-lg;
}

.dashboard-consequences {
  @apply mx-20 my-5 px-5 pt-5 w-10/12 justify-center rounded shadow-lg;
}

.dashboard-aggregate {
  @apply mx-20 my-5 px-5 w-6/12 min-h-128 max-h-144 justify-center overflow-y-auto rounded shadow-lg bg-white;
}

.dashboard-optionsblock {
  @apply mx-20 mt-5 px-5 pt-4 min-w-512px justify-center overflow-x-auto rounded shadow-lg;
}

.dashboard-hypotheticalblock {
  @apply mx-20 mt-5 px-5 justify-center w-10/12 rounded shadow-lg bg-white p-5;
}

.dashboard-options {
  @apply mx-4 p-4 justify-center rounded shadow-lg;
}

.dashboard-hypothetical {
  @apply mx-4 p-4 justify-center bg-white rounded shadow-lg;
}

.dashboard-motivation {
  @apply mx-4 my-5 p-4 justify-center rounded shadow-lg;
}

.dashboard-character {
  @apply mx-20 my-5 px-5 pt-5 w-10/12 justify-center rounded shadow-lg bg-white p-5;
}

.dashboard-virtueRank {
  @apply mx-20 my-5 px-5 pt-5 w-10/12 justify-center rounded shadow-lg bg-white;
}

.dashboard-home-grid-item {
  @apply bg-white rounded-lg shadow-lg text-center;
}

.draggable {
  /* just to size it to content */
  display: inline-block;
  /* opaque background is important for performance */
  background: white;
  /* avoid selecting text while dragging */
  user-select: none;
}

.ball {
  @apply w-10 h-10 rounded-full bg-red-400;
}

.navbar-link {
  @apply block mt-4 lg:inline-block lg:mt-1 text-blue-600 hover:text-blue-900 mr-4;
}

.btn {
  @apply text-white font-bold py-2 px-4 rounded bg-primary hover:brightness-125;
}

li {
  list-style-type: none;
}

textarea {
  resize: vertical;
}

.average-slider {
  height: 44px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
.average-slider:focus {
  outline: none;
}
.average-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 30px;
  box-shadow: 0px 0px 0px #A6A6A6;
  background: #42a5f5;
  border-radius: 0px;
  border: 4px solid #39576d;
}
.average-slider::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #A6A6A6;
  border: 4px solid #000000;
  height: 30px;
  width: 10px;
  border-radius: 0px;
  background: #FFFFFF;
  -webkit-appearance: none;
  margin-top: -4px;
}
.average-slider:focus::-webkit-slider-runnable-track {
  background: #42a5f5;
}
.average-slider::-moz-range-track {
  width: 100%;
  height: 30px;
  box-shadow: 0px 0px 0px #A6A6A6;
  background: #42a5f5;
  border-radius: 0px;
  border: 4px solid #e3f2fd;
}
.average-slider::-moz-range-thumb {
  box-shadow: 0px 0px 0px #A6A6A6;
  border: 4px solid #000000;
  height: 30px;
  width: 10px;
  border-radius: 0px;
  background: #FFFFFF;
}
.average-slider::-ms-track {
  width: 100%;
  height: 30px;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.average-slider::-ms-fill-lower {
  background: #42a5f5;
  border: 4px solid #e3f2fd;
  border-radius: 0px;
  box-shadow: 0px 0px 0px #A6A6A6;
}
.average-slider::-ms-fill-upper {
  background: #42a5f5;
  border: 4px solid #e3f2fd;
  border-radius: 0px;
  box-shadow: 0px 0px 0px #A6A6A6;
}
.average-slider::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #A6A6A6;
  border: 4px solid #000000;
  height: 30px;
  width: 10px;
  border-radius: 0px;
  background: #FFFFFF;
}
.average-slider:focus::-ms-fill-lower {
  background: #42a5f5;
}
.average-slider:focus::-ms-fill-upper {
  background: #42a5f5;
}

.form-input-general {
  @apply shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none;
}

.loading-spinner {
  @apply ease-linear rounded-full border-8 border-t-8 border-gray-200;
  border-top-color: #a8a182;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.nav-link {
  @apply py-4 px-2 text-gray-500 font-semibold hover:text-primary transition duration-300;
}

.nav-link-active {
  @apply py-4 px-2 text-primary border-b-4 border-primary font-semibold;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
